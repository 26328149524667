@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: rgba(30,41,59);
}
body,
.typeface-bodoni {
  font-family: "Libre Bodoni";  
}
input,
button,
textarea,
.typeface-assistant {
  font-family: "Assistant";  
}
